/*-------------------------------------------------------Important Notes----------------------------------------------------------------------

- Text colour schemes:  #282c34 is used for venue row text (except for the "perks" text, this is #c9d3db)
- The awesome website for the colour gradient used on cards and the nav bar is: https://www.colorzilla.com/gradient-editor/

/*



/*--------------------------------------------------Navigation Header Section Below--------------------------------------------------------*/


.nav-header {
    display: flex;
    justify-content: space-around;
    background-color: #282c34;
}

.title {
    background-color: #282c34;
    flex-wrap: wrap;
    font-size: calc(35px + 2vmin);
    color: #edf1f4;
    letter-spacing: 3px;
}

.Logo {
    pointer-events: none;
}

.nav-list li {
    list-style: none;
    text-align: center;
    color: #282c34;
    font-weight: 500;
}

.nav-list li:hover {
    text-decoration: underline;
    transition-delay: 2s;
}

.navigation-row {
    /* background-color: rgb(236, 242, 242); */
    opacity: 80%;
    margin-top: 40px;
    padding: 4px;



    background: #828c95;
    /* Old browsers */
    background: -moz-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#828c95', endColorstr='#28343b', GradientType=0);
    /* IE6-9 */
}

.nav-column {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.nav-column-logo {
    display: flex;
    align-items: left;
    justify-content: left;
    padding-top: 20px;
}

.feedback {
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    background: #828c95;
    /* Old browsers */
    background: -moz-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#828c95', endColorstr='#28343b', GradientType=0);
    /* IE6-9 */
}

.feedback-additional {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align-last: center;
    margin-top: 40px;
    font-weight: 500;
    color: #c9d3db;
    font-size: calc(4px + 2vmin);
    padding-left: 50px;
    padding-right: 50px;
}

/*--------------------------------------------------Home Page Section Below--------------------------------------------------------*/

.home-page h2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    color: #c9d3db;
}

.home-page p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align-last: center;
    margin-top: 40px;
    font-weight: 500;
    color: #c9d3db;
    font-size: calc(4px + 2vmin);
    padding-left: 50px;
    padding-right: 50px;
}



/*--------------------------------------------------Drinks Landing Page Section Below--------------------------------------------------------*/

.drinks-landing-page-content {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 20px;
    color: #282c34;
    font-weight: 700;
    font-size: x-large;

}

div.col {
    padding-left: 30px;
    padding-right: 30px;
}

.landing-page-card {
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    background: #828c95;
    /* Old browsers */
    background: -moz-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#828c95', endColorstr='#28343b', GradientType=0);
    /* IE6-9 */

}

.separator-row {
    margin: 20px;
    color: #282c34;
}

button {
    color: #c9d3db;
    background-color: #282c34;
    box-shadow: 0 8px 16px 0 #282c34(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 12px;
    border-width: 1.5px;
    font-size: smaller;

}

button:hover {
    opacity: 80%;
}

/*-------------------------------------------------------Venue Page Section Below------------------------------------------------------------*/


.page-container {
    background-color: #282c34;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-title {
    display: flex !important;
    text-align: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 80px;
    color: #c9d3db;
}


/*Main row each venue sits in*/
.venue-page {
    padding-top: 20px;
    padding-bottom: 25px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;

}

.venue-page .venue {
    margin-bottom: 60px;
    width: 80%;
    border-radius: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #828c95;
    /* Old browsers */
    background: -moz-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #828c95 36%, #828c95 36%, #b5bdc8 100%, #28343b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#828c95', endColorstr='#28343b', GradientType=0);
    /* IE6-9 */
}

/*Perks Section Title*/
.venue-page h3 {
    font-weight: 700;
    font-size: large;
}

/*Title*/
.venue-page h2 {
    font-weight: 700;
}

/*Google Map*/
.venue-page iframe {
    border-radius: 12px;
    max-width: 100%;
    max-height: 100%;
}

/* Perks Section */

.venue-page p {
    font-weight: 600;
    margin: 10px;
    /* background-color: #282c34;
    color: #c9d3db; */
}

/*Perks Section text*/
.perks-section-all {
    width: 75%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: inline-block;
    
}

.p1 {
    font-weight: 600;
    margin: 0;
    background-color: #282c34;
    color: #c9d3db;
    border-radius: 12px;
    padding: 5px;
}

/*Perks Section*/
.p2 {
    font-weight: 600;
    margin: 0;
    background-color: #282c34;
    color: #c9d3db;
    border-radius: 12px;
    padding: 5px;
}

/*Perks Section*/
.p3 {
    font-weight: 600;
    margin: 0;
    background-color: #282c34;
    color: #c9d3db;
    border-radius: 12px;
    padding: 5px;
}


/*Menu & Gallery*/
.additional-info-list {
    list-style: none;
    font-size: large;
    padding: 5px;
    font-weight: 500;
}

.additional-info-list:hover {
    text-decoration: underline;
    transition-delay: 2s;
    cursor: pointer;
}

/*Menu & Gallery Coming Soon*/
.coming-soon {
    font-style: italic;
    font-size: small;
    list-style: none;
}

